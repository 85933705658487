import "./app.scss";
import { Layout } from "../components/layout";
import ValidateAccessToken from "../service/samUserService";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";


export function App() {
  let redirect = false;

  if (window === window.parent) {
    setTimeout(() => {
      window.location.href =
        window.location.origin.replace("unionadmin.", "") + "/unionadmin/home";
    }, 10000);
    redirect = true;
  }
  if (window !== window.parent) {
    ValidateAccessToken();
  }
  
  return (
    <>
      {redirect && (
        <div id="overlay">
          <div id="text">
            "You are viewing the standalone Admin Application, You will be
            redirected to Union Portal"
          </div>
        </div>
      )}
      <div className="App">
      <Layout />
      </div>
    </>
  );
}
