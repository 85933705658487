import { useEffect, useState } from "react";
import "./createuser.scss";
import moment from "moment";
import { User } from "../../model/user.model";
import AdminUserInfo from "../../service/adminUserService";
import { createUser } from "../../service/userService";
import { useNavigate } from "react-router-dom";
import { Notification } from "../shared/notification";
import { UnionModal } from "../shared/modal/UnionModal";

interface CreateUserProps {
  isVisible: boolean;
  onCancel: () => void;
  users: User[];
  fetchUsers: () => void;
}

const CreateUser: React.FC<CreateUserProps> = ({ isVisible, onCancel, users, fetchUsers}) => {
  let navigate = useNavigate();  

  const [formErrors, setFormErrors] = useState({
    userEmailIdValid: false,
    requestedByValid: false,
    accessStartDateTimeValid: false,
    accessEndDateTimeValid: true,
    formValid: false,
    validationError: {
      userEmailIdError: "",
      requestedByError: "",
      accessStartDateTimeError: "",
      accessEndDateTimeError: "",
    },
  });

  const [userInfo, setUserInfo] = useState({
    userEmailId: "",
    applicationId: "rfr-calculator",
    requestedBy: "",
    accessStartDateTime: "",
    accessEndDateTime: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [userCreated, setUserCreated] = useState(false);
  const [errorResponse, setErrorResponse] = useState("");
  const [showModal, setShowModal] = useState(isVisible);
  const dateFormat = "yyyy/MM/DD HH:mm:ss";
  useEffect(() => {
    setShowModal(isVisible);
  }, [isVisible]);

  const handleChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;
    validateField(name, value);
    setUserInfo((state) => ({ ...state, [name]: value }));
  };

  const isDateValid = (date: string) => {
    return moment(
      moment(date).format("yyyy/MM/DD HH:mm:ss"),
      dateFormat,
      true
    ).isValid();
  };

  const validateDate = (date: string) => {
    return isDateValid(date);
  };

  const validateEndDate = (date: string) => {
    if (date.trim() === "") {
        return true;
    }
    return isDateValid(date);
};

  const validateEmail = (email: any) => {
    const regx = /^([\w'.+-]+)@([\w-]+\.)+([\w]{2,})$/i;
    return regx.test(email);
  };

  const validateField = (fieldName: any, value: any) => {
    switch (fieldName) {
      case "userEmailId":
        formErrors.userEmailIdValid = validateEmail(value);
        formErrors.validationError.userEmailIdError =
          formErrors.userEmailIdValid
            ? ""
            : "User Email ID is either null or not in valid format";
        if (value.length > 100) {
          formErrors.userEmailIdValid = false;
          formErrors.validationError.userEmailIdError =
            "Max 100 characters are allowed";
        }
        break;

      case "requestedBy":
        formErrors.requestedByValid = validateEmail(value);
        formErrors.validationError.requestedByError =
          formErrors.requestedByValid
            ? ""
            : "Requested By Email ID is either null or not in valid format";
        break;

      case "accessStartDateTime":
        handleAccessStartDateTimeValidation(value);
        break;

      case "accessEndDateTime":
        handleAccessEndDateTimeValidation(value);
        break;
    }

    updateFormValidity();
  };

  const handleAccessStartDateTimeValidation = (value: any) => {
    if (validateDate(value) && value !== null) {
      const startDate = moment.utc(value);
      const endDate = moment.utc(userInfo.accessEndDateTime);
      if (userInfo.accessEndDateTime !== "" && startDate >= endDate) {
        formErrors.accessStartDateTimeValid = false;
        formErrors.validationError.accessStartDateTimeError =
          "Access Start DateTime can't be greater than or equal the Access End DateTime";
      } else {
        formErrors.accessStartDateTimeValid = true;
        formErrors.accessEndDateTimeValid = true;
        formErrors.validationError.accessStartDateTimeError = "";
        formErrors.validationError.accessEndDateTimeError = "";
      }
    } else {
      formErrors.accessStartDateTimeValid = false;
      formErrors.validationError.accessStartDateTimeError =
        "Access Start DateTime is not valid";
    }
  };

  const handleAccessEndDateTimeValidation = (value: any) => {
    formErrors.accessEndDateTimeValid = validateEndDate(value);
    formErrors.validationError.accessEndDateTimeError =
      formErrors.accessEndDateTimeValid
        ? ""
        : "Access End DateTime is not valid";
    if (
      value !== null &&
      moment.utc(value).format() <=
        moment.utc(userInfo.accessStartDateTime).format()
    ) {
      formErrors.accessEndDateTimeValid = false;
      formErrors.validationError.accessEndDateTimeError =
        "Access End DateTime can't be less than or equal the Access Start DateTime";
    }
  };

  const updateFormValidity = () => {
    formErrors.formValid =
      formErrors.userEmailIdValid &&
      formErrors.requestedByValid &&
      formErrors.accessStartDateTimeValid &&
      formErrors.accessEndDateTimeValid;
  };

  const handleCancel = () => {
    resetFormState();
    onCancel();
    navigate("/home");
  };

  const checkUserExists = async () => {
    const isUserExists = users.some((x: User) => {
      if (
        x.userId === userInfo.userEmailId &&
        x.applicationId === userInfo.applicationId
      ) {
        return true;
      }
    });
    return isUserExists;
  };

  const resetFormState = () => {
    setUserInfo({
      userEmailId: "",
      applicationId: "rfr-calculator",
      requestedBy: "",
      accessStartDateTime: "",
      accessEndDateTime: "",
    });
    setFormErrors({
      userEmailIdValid: false,
      requestedByValid: false,
      accessStartDateTimeValid: false,
      accessEndDateTimeValid: true,
      formValid: false,
      validationError: {
        userEmailIdError: "",
        requestedByError: "",
        accessStartDateTimeError: "",
        accessEndDateTimeError: "",
      },
    });

    formErrors.formValid = false;
  };

  const handleSubmit = async () => {
    const adminUserInfo = AdminUserInfo();

    if (adminUserInfo) {
      const isUserExists = await checkUserExists();
      if (isUserExists) {
        setErrorResponse("User already exists for this Application ID");
        setTimeout(() => {
          setErrorResponse("");
        }, 5000);
        return;
      }
      const userData: User = {
        userId: userInfo.userEmailId,
        applicationId: userInfo.applicationId,
        requestedBy: userInfo.requestedBy,
        startDate: moment(userInfo.accessStartDateTime).format(
          "yyyy/MM/DD HH:mm:ss"
        ),
        endDate:
          userInfo.accessEndDateTime !== ""
            ? moment(userInfo.accessEndDateTime).format("yyyy/MM/DD HH:mm:ss")
            : "",
        createdBy: adminUserInfo.email,
        createdOn: moment.utc().format("yyyy/MM/DD HH:mm:ss"),
        lastUpdatedBy: adminUserInfo.email,
        lastUpdatedOn: moment.utc().format("yyyy/MM/DD HH:mm:ss"),
      };
      setIsLoading(true);
      createUser(userData)
        .then(() => {
          setIsLoading(false);
          setUserCreated(true);
          resetFormState();
          fetchUsers();
          setTimeout(() => {
            setUserCreated(false);
          }, 5000);
        })
        .catch((error) => {
          setIsLoading(false);
          if (error !== null && error !== undefined) {
            if (error.data["error"].errors.length > 0) {
              let errorField = error.data["error"].errors[0].errorField;
              let errorDescription =
                error.data["error"].errors[0].errorDescription;
              setErrorResponse(errorField + " " + errorDescription);
            }
          } else {
            setErrorResponse("Error occurred while creating user");
          }
          setTimeout(() => {
            setErrorResponse("");
          }, 1000);
          console.error(error);
        });
    }
  };

  return (
    <>
      {isLoading && <div id="loading">Loading...</div>}
      {userCreated && (
        <Notification type="success" message="User Created Successfully" />
      )}
      {errorResponse !== "" && (
        <Notification type="warning" message={errorResponse} />
      )}
      {showModal && (
        <UnionModal
          id="createUser-modal"
          title="Create Admin User"
          size="small"
          actions={[
            {
              buttonId: "create-user",
              buttonLabel: "Create",
              buttonType: "Primary",
              disabled: !formErrors.formValid,
              onButtonClick: () => {
                handleSubmit();
                setShowModal(false);
                handleCancel();
              },
              buttonSize: "small",
            },
            {
              buttonId: "cancel-submit",
              buttonLabel: "Cancel",
              buttonType: "Subtle",
              disabled: false,
              onButtonClick: () => {
                handleCancel();
              },
              buttonSize: "small",
            },
          ]}
          onClose={onCancel}
          actionsLayout={"end"}
        >
          <form className="form" onSubmit={handleSubmit}>
            <div className="user-access">
              <div>
                <label htmlFor="email"> Email *</label>
                <input
                  data-testid="email"
                  type="email"
                  name="userEmailId"
                  value={userInfo.userEmailId}
                  onChange={handleChange}
                  placeholder="User Email ID"
                  required
                />
              </div>
              <div>
                <label htmlFor="application-id">Application Id *</label>
                <select
                  data-testid={"applicationId"}
                  id="applicationId"
                  name="applicationId"
                  value={userInfo.applicationId}
                  onChange={handleChange}
                  defaultValue="rfr-calculator"
                >
                  <option value="rfr-calculator">Risk Free Rates</option>
                </select>
              </div>
              <div>
                <label htmlFor="requested-by">Requested By *</label>
                <input
                  data-testid={"requestedBy"}
                  id="requestedBy"
                  type="email"
                  name="requestedBy"
                  value={userInfo.requestedBy}
                  onChange={handleChange}
                  placeholder="Requester Email Id"
                ></input>
              </div>
              <div>
                <label htmlFor="start-date-time">Access Start DateTime (UTC) *</label>
                <input
                  data-testid={"accessStartDateTime"}
                  type="datetime-local"
                  name="accessStartDateTime"
                  value={userInfo.accessStartDateTime}
                  onChange={handleChange}
                  min="2020-01-01T00:00"
                  max="9999-12-31T00:00"
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                ></input>
              </div>
              <div>
                <label htmlFor="end-date-time">Access End DateTime (UTC-Optional)</label>
                <input
                  id="accessEndDateTime"
                  data-testid="accessEndDateTime"
                  type="datetime-local"
                  name="accessEndDateTime"
                  value={userInfo.accessEndDateTime}
                  onChange={handleChange}
                  min={userInfo.accessStartDateTime}
                  max="9999-12-31T00:00"
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                ></input>
              </div>
            </div>
            {formErrors.formValid === false && (
              <div
                className="error"
                id="validationError"
                data-testid="validationError"
              >
                <p>{formErrors.validationError.userEmailIdError}</p>
                <p>{formErrors.validationError.requestedByError}</p>
                <p>{formErrors.validationError.accessStartDateTimeError}</p>
                <p>{formErrors.validationError.accessEndDateTimeError}</p>
              </div>
            )}
          </form>
        </UnionModal>
      )}
    </>
  );
};

export default CreateUser;
