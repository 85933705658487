import React, { useEffect, useState } from "react";
import { Features, FeaturesAudit } from "../../model/feature.model";
import {
  getFeatures,
  updateFeature,
  deleteFeature,
  getFeaturesAudit,
  createFeatureAudit,
} from "../../service/featureService";
import { Notification } from "../shared/notification";
import "./feature.scss";
import { Button } from "@medm-ui/button";
import Createfeature from "./create-feature";
import UpdateFeature from "./update-feature";
import AdminUserInfo from "../../service/adminUserService";
import { AgGridReact, CustomCellRendererProps } from 'ag-grid-react';
import { ColDef, Column } from "ag-grid-community";
import { checkboxCellRenderer, buttonCellRenderer, createdCellRenderer } from "./cell-renderers";

type FeatureData = Features;

const Feature: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [userFeatureData, setUserFeatureData] = useState<FeatureData[]>([]);
  const [featuresAudit, setFeaturesAudit] = useState<FeaturesAudit[]>([]);
  const [featureUpdated, setFeatureUpdated] = useState(false);
  const [errorResponse, setErrorResponse] = useState("");
  const [notificationMessage, setNotificationMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [selectedFeatureKey, setSelectedFeatureKey] = useState("");
  const [selectedFeatureId, setSelectedFeatureId] = useState(0)
  const timeout = 5000;
  const currentTime = new Date().toISOString();
  const adminUserInfo = AdminUserInfo();


  const handleCheckboxChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    row: FeatureData,
    column: Column
  ) => {
    const isChecked = event.target.checked;
    const env = column.getColId();
    const previousAuditData = featuresAudit.find(
      (audit) => audit.featureId === row.featureId
    );
    setUserFeatureData((prevData) => {
      const updatedData = prevData.map((item) =>
        item.featureId === row.featureId ? { ...item, [env]: isChecked } : item
      );

      const updatedFeature = updatedData.find(
        (item) => item.featureId === row.featureId
      );

      if (updatedFeature) {
        updateFeature(updatedFeature)
          .then(() => {
            setNotificationMessage("Feature updated successfully");
            setFeatureUpdated(true);
            const auditData = {
              id: 0,
              featureId: row.featureId,
              createdBy: previousAuditData?.createdBy || "",
              createdOn: previousAuditData?.createdOn || "",
              lastUpdatedBy: adminUserInfo.email,
              lastUpdatedOn: currentTime,
              comments: `Feature number ${row.featureId} was updated by ${adminUserInfo.email} on ${currentTime}`,
            };
            createFeatureAudit(auditData);
            fetchData()
            setTimeout(() => {
              setNotificationMessage("");
              setFeatureUpdated(false);
            }, timeout);
          })
          .catch((error) => {
            setErrorResponse("Error updating feature");
          });
      }

      return updatedData;
    });
  };


  const columnDefs: ColDef[] = [
    { field: 'featureKey', resizable: true },
    {
      field: 'production',
      resizable: true,
      width: 150,
      cellRenderer: checkboxCellRenderer,
      cellRendererParams: { onChange: handleCheckboxChange }
    },
    {
      field: 'uat',
      resizable: true,
      width: 100,
      cellRenderer: checkboxCellRenderer,
      cellRendererParams: { onChange: handleCheckboxChange }
    },
    {
      field: 'dev',
      resizable: true,
      width: 100,
      cellRenderer: checkboxCellRenderer,
      cellRendererParams: { onChange: handleCheckboxChange }
    },
    {
      field: 'qa',
      resizable: true,
      width: 100,
      cellRenderer: checkboxCellRenderer,
      cellRendererParams: { onChange: handleCheckboxChange }
    },
    {
      headerName: 'Last updated by',
      cellRenderer: (props: CustomCellRendererProps) => getAuditForFeature(props.data.featureId).lastUpdatedBy
    },
    {
      headerName: 'Last updated on',
      cellRenderer: (props: CustomCellRendererProps) => getAuditForFeature(props.data.featureId).lastUpdatedOn
    },
    {
      field: 'createdOn',
      resizable: true,
      cellRenderer: createdCellRenderer,
      width: 350
    },
    {
      headerName: 'Actions',
      width: 200,
      sortable: false,
      suppressHeaderFilterButton: true,
      cellRenderer: (props: CustomCellRendererProps) => <div className="created-on-cell">{
        buttonCellRenderer({
          ...props,
          ...{
            text: "Edit",
            onClick: () => {
              handleEdit(props.data.featureKey, props.data.featureId);
            }
          }
        })}
        {buttonCellRenderer({
          ...props,
          ...{
            text: "Delete",
            onClick: () => {
              handleDelete(props.data.featureKey, props.data.featureId);
            }
          }
        })
        }
      </div>
    }
  ];

  const getAuditForFeature = (featureId: number): FeaturesAudit =>
  (featuresAudit.filter(
    (audit) => audit.featureId === featureId
  ).sort(
    (a, b) =>
      new Date(b.lastUpdatedOn).getTime() -
      new Date(a.lastUpdatedOn).getTime()
  ).map((audit) => audit)[0] || "N/A");

  const defaultColDef = {
    sortable: true,
    filter: true,
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const features = await getFeatures();
      const audits = await getFeaturesAudit();
      setUserFeatureData(features);
      setFeaturesAudit(audits || []);
    } catch (error) {
      console.error("Error fetching features:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEdit = (featureKey: string, featureId: number) => {
    setSelectedFeatureKey(featureKey);
    setSelectedFeatureId(featureId)
    setShowUpdateModal(true);
  };

  const handleDelete = async (featureKey: string, featureId: number) => {
    try {
      const deletedFeature = await deleteFeature(featureKey);
      const previousAuditData = featuresAudit.find(
        (audit) => audit.featureId === featureId
      );
      if (deletedFeature !== undefined) {
        setNotificationMessage(`${featureKey} feature deleted successfully`);
        const auditData = {
          id: 0,
          featureId: featureId,
          createdBy: previousAuditData?.createdBy || "",
          createdOn: previousAuditData?.createdOn || "",
          lastUpdatedBy: adminUserInfo.email,
          lastUpdatedOn: currentTime,
          comments: `Feature number ${featureId} was deleted by ${adminUserInfo.email} on ${currentTime}`,
        };
        createFeatureAudit(auditData);
        setFeatureUpdated(true);
        await fetchData();
        setTimeout(() => {
          setNotificationMessage("");
          setFeatureUpdated(false);
        }, timeout);
      }
    } catch (error) {
      setErrorResponse("Error deleting feature");
    }
  };

  return (
    <div>
      <div className="create-button">
        <Button
          data-test-id={"CreateNewUser"}
          buttonId={"CreateNewUser"}
          buttonLabel={"Create New Feature"}
          buttonType={"Primary"}
          showIcon={true}
          disabled={false}
          iconProps={{
            color: "Black",
            size: "20",
            icon: "plus",
          }}
          onButtonClick={() => setShowModal(true)}
          buttonSize={"small"}
        />
      </div>
      <div>
        {isLoading ? (
          <p>Loading</p>
        ) : (
          <div>
            {showModal && (
              <Createfeature
                showModal={showModal}
                setShowModal={setShowModal}
                fetchData={fetchData}
              />
            )}
            {showUpdateModal && (
              <UpdateFeature
                showUpdateModal={showUpdateModal}
                setShowUpdateModal={setShowUpdateModal}
                fetchData={fetchData}
                featureKey={selectedFeatureKey}
                selectedFeatureId={selectedFeatureId}
                featuresAudit={featuresAudit}
              />
            )}

            <div className="ag-theme-quartz" style={{ height: 600, width: "100%" }}>
              <AgGridReact
                defaultColDef={defaultColDef}
                rowData={userFeatureData}
                rowSelection="multiple"
                alwaysMultiSort
                columnDefs={columnDefs}
              />
            </div>
          </div>
        )}
      </div >
      {featureUpdated && (
        <Notification
          className="notification"
          type="success"
          message={notificationMessage}
        />
      )}
      {
        errorResponse && (
          <Notification
            className="notification"
            type="error"
            message={errorResponse}
          />
        )
      }
    </div >
  );
};

export default Feature;
