import React, { useState} from "react";
import { Notification } from "../shared/notification";
import { UnionModal } from "../shared/modal/UnionModal";
import { updateFeatureKey, createFeatureAudit } from "../../service/featureService";
import AdminUserInfo from "../../service/adminUserService";
import { FeaturesAudit } from "../../model/feature.model";

interface UpdateFeatureProps {
  showUpdateModal: boolean;
  setShowUpdateModal: (show: boolean) => void;
  fetchData: () => void;
  featureKey: string;
  selectedFeatureId: number
  featuresAudit: FeaturesAudit[]
}

const UpdateFeature: React.FC<UpdateFeatureProps> = ({
  showUpdateModal,
  setShowUpdateModal,
  fetchData,
  featureKey,
  selectedFeatureId,
  featuresAudit
}) => {
  const [featureInfo, setFeatureInfo] = useState({
    featureKey: featureKey,
    updatedFeatureKey: ""
  });
  const [errorResponse, setErrorResponse] = useState<string>("");
  const [notificationMessage, setNotificationMessage] = useState<string>("");
  const [featureCreated, setFeatureCreated] = useState<boolean>(false);

  const handleCloseModal = () => {
    setShowUpdateModal(false);
  };

  const resetForm = () =>
    setFeatureInfo({
      featureKey: featureKey,
      updatedFeatureKey: ""
    });

  const handleSubmit = async () => {
    const adminUserInfo = AdminUserInfo()
    const previousAuditData = featuresAudit.find(
      (audit) => audit.featureId === selectedFeatureId
    );
    try {
      await updateFeatureKey(featureInfo);
      const auditData = {
        id: 0,
        featureId: selectedFeatureId,
        createdBy: previousAuditData?.createdBy || "",
        createdOn: previousAuditData?.createdOn || "",
        lastUpdatedBy: adminUserInfo.email,
        lastUpdatedOn: new Date().toISOString(),
        comments: `FeatureId ${selectedFeatureId} was renamed by ${adminUserInfo.email}`,
      };      
      await createFeatureAudit(auditData)
      fetchData()
      setNotificationMessage("Feature created successfully");
      setFeatureCreated(true);
      setShowUpdateModal(false);
      fetchData();
      setTimeout(() => {
        setNotificationMessage("");
        setFeatureCreated(false);
      }, 5000);
      resetForm();
    } catch (error) {
      if (error instanceof Error) {
        setErrorResponse(error.message || "Error creating Feature");
      } else {
        setErrorResponse("Error creating Feature");
      }
      console.error("Error creating Feature:", error);
    }
  };

  const handleChangeInput = (data: React.ChangeEvent<HTMLInputElement>) => {
    const updatedFeatureKey = data.target.value.trim();
    setFeatureInfo((state) => ({ ...state, updatedFeatureKey: updatedFeatureKey }));    
  };

  return (
    <div>
      {showUpdateModal && (
        <UnionModal
          title="Update Feature"
          size="small"
          actions={[
            {
              buttonId: "create-user",
              buttonLabel: "Update",
              buttonType: "Primary",
              onButtonClick: handleSubmit,
              buttonSize: "small",
            },
            {
              buttonId: "cancel-submit",
              buttonLabel: "Cancel",
              buttonType: "Subtle",
              onButtonClick: handleCloseModal,
              buttonSize: "small",
            },
          ]}
          onClose={handleCloseModal}
          actionsLayout={"end"}
        >
          <form className="form">
            <div className="user-access">
              <div>
              <label id="featureKey">Current feature key</label>
                <input
                  data-testid="text"
                  type="text"
                  name="featureKey"
                  value={featureKey}
                  readOnly
                />
                <label id="updatedFeatureKey">Updated feature key *</label>
                <input
                  data-testid="text"
                  type="text"
                  name="updatedFeatureKey"
                  placeholder="Updated feature key"
                  autoComplete="off"
                  onChange={handleChangeInput}
                  required
                />
              </div>
            </div>
          </form>
        </UnionModal>
      )}
      {featureCreated && (
        <Notification
          className="notification"
          type="success"
          message={notificationMessage}
        />
      )}
      {errorResponse && (
        <Notification
          className="notification"
          type="error"
          message={errorResponse}
        />
      )}
    </div>
  );
};

export default UpdateFeature;
