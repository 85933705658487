import { Button } from "@medm-ui/button";
import { Column } from "ag-grid-community";
import { CustomCellRendererProps } from "ag-grid-react";
import { ChangeEvent } from "react";
import { Features } from "../../model/feature.model";
import { Alert, Checkbox } from "@mui/material";
import moment from "moment";

export const createdCellRenderer = (
    props: CustomCellRendererProps
) => {
    const { createdOn } = props.data;
    return <div className="created-on-cell">
        {displayCreatedDate(createdOn)}
        {displayNotificationIfNeeded(createdOn)}
    </div>
}

export const buttonCellRenderer = (
    props: CustomCellRendererProps & { text: string, onClick: () => void },
) => {
    return (
        <Button buttonSize="small" buttonLabel={props.text} onButtonClick={props.onClick} />
    );
};

export const checkboxCellRenderer = (
    props: CustomCellRendererProps & { onChange: (event: ChangeEvent, row: Features, column: Column) => void },
) => {
    const handleOnChange = (event: ChangeEvent) => {
        const {
            column,
            node: { rowIndex },
        } = props;

        if (column && rowIndex !== null && rowIndex >= 0) {
            props.onChange(event, props.data, column);
        }
    };

    return (
        <Checkbox
            checked={props.value}
            onChange={handleOnChange}
        />
    );
};

const checkIfDateIsOverPastDays = (date: Date, daysCount: number): boolean => {
    const now = new Date();
    const msToDay = 1000 * 60 * 60 * 24;
    const dayDifference = (now.getTime() - date.getTime()) / msToDay;
    if (dayDifference > daysCount) {
        return true;
    }
    return false;
}

const displayCreatedDate = (date?: string): string => {
    if (date === undefined || date.length === 0) {
        return "N/A";
    }
    return date;
}
const displayNotificationIfNeeded = (date?: string): React.ReactElement => {
    if (date === undefined || date.length === 0) {
        return <></>;
    }
    const parsedDate = moment(date, "DD-MM-YYYY HH:mm:ss", true).toDate();

    const displayExpiringNotification = checkIfDateIsOverPastDays(parsedDate, 30);
    const displayExpiredNotification = checkIfDateIsOverPastDays(parsedDate, 90);
    return (<>
        {displayExpiringNotification && !displayExpiredNotification && <Alert severity="warning" title="This feature will expire soon. Please don't forget to remove the feature flagging code from codebase.">EXPIRING</Alert>}
        {displayExpiredNotification && <Alert severity="error" title="This feature is expired since created more than 90 days ago.">EXPIRED</Alert>}
    </>)
}