import { AgGridReact } from "ag-grid-react";
import { useEffect, useMemo, useState } from "react";
import { getUsers } from "../../service/userService";
import "./home.scss";
import { Button } from "@medm-ui/button";
import { Delete } from "./delete";
import moment from "moment";
import CreateUser from "../createuser";
import { UpdateUser } from "../updateuser";
import { User } from "../../model/user.model";
import { ColDef } from "ag-grid-community";

export function Home() {
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState<User[]>([]);
  const [userPermissionData, setUserPermissionData] = useState([]);
  const [isCreateUserModalVisible, setIsCreateUserModalVisible] = useState(false);

  const showCreateModal = () => setIsCreateUserModalVisible(true);
  const hideCreateModal = () => setIsCreateUserModalVisible(false);

  const handleCreateUserButtonClick = () => {
    showCreateModal()
  }

  const dateFormatter = (value: any) => {
    if (value === null || value === "") {
      return "";
    }
    return moment(value).format("MM/DD/YYYY hh:mm:ss A");
  };

  const dateComparator = (date1: string, date2: string) => {
    if (date1 === "" && date2 === "") {
      return 0;
    }

    if (date1 === "") {
      return -1;
    } else if (date2 === "") {
      return 1;
    }

    let difference = new Date(date1).getTime() - new Date(date2).getTime();

    return difference;
  };

  const containerStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const columnDefs: ColDef[] = [
    { field: "userId", resizable: true, width: 250 },
    { field: "applicationId", resizable: true, width: 120 },
    {
      field: "startDate",
      resizable: true,
      width: 190,
      comparator: dateComparator,
    },
    {
      field: "endDate",
      resizable: true,
      width: 190,
      comparator: dateComparator,
    },
    { field: "createdBy", resizable: true },
    {
      field: "createdOn",
      resizable: true,
      width: 190,
      comparator: dateComparator,
    },
    { field: "lastUpdatedBy", resizable: true, width: 140, },
    {
      field: "lastUpdatedOn",
      resizable: true,
      width: 140,
      comparator: dateComparator,
    },
    { field: "requestedBy", resizable: true },
    {
      field: "Action",
      resizable: true,
      cellRenderer: "LinkComponent",
      width: 140,
      sortable: false,
      filter: false,
    },
  ];
  const defaultColDef = {
    sortable: true,
    filter: true,
  };

  const LinkComponent = (props: any) => {
    let userId: string = props.data.userId;
    let applicationId: string = props.data.applicationId;

    return (
      <>
        <Edit userId={userId} applicationId={applicationId}></Edit>
        <Delete userId={userId} applicationId={applicationId}></Delete>
      </>
    );
  }

  const [showEditModal, setShowEditModal] = useState(false)
  const openEditModal = () => setShowEditModal(true);
  const closeEditModal = () => setShowEditModal(false);
  const [uid, setUid] = useState("");
  const [aid, setAid] = useState("");

  const Edit = (props: { userId: string; applicationId: string }) => {
    const editUser = (userId: any, applicationId: any) => {
      setUid(userId)
      setAid(applicationId)
      openEditModal()
    };
    return <a onClick={() => {
      editUser(props.userId, props.applicationId)
    }}>Edit</a>;
  }

  const fetchUsers = () => {
    setIsLoading(true);
    getUsers()
      .then((data) => {
        data.forEach((d: any) => {
          d.startDate = dateFormatter(d.startDate);
          d.endDate = dateFormatter(d.endDate);
          d.createdOn = dateFormatter(d.createdOn);
          d.lastUpdatedOn = dateFormatter(d.lastUpdatedOn);
        });
        setUserPermissionData(data);
        setIsLoading(false);
        setUsers(data);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  }

  useEffect(() => {
    fetchUsers()
  }, []);

  return (
    <>
      {isLoading && <div id="loading">Loading...</div>}
      <div className="page-header">
        <div className="left">
          <h3>User Permission Data</h3>
        </div>
        <div className="right">
          <Button
            data-test-id={"CreateNewUser"}
            buttonId={"CreateNewUser"}
            buttonLabel={"Create New User"}
            buttonType={"Primary"}
            showIcon={true}
            disabled={false}
            iconProps={{
              color: "Black",
              size: "20",
              icon: "plus",
            }}
            onButtonClick={() => handleCreateUserButtonClick()}
            buttonSize={"small"}></Button>
        </div>
      </div>
      <div><CreateUser isVisible={isCreateUserModalVisible} onCancel={hideCreateModal} users={users} fetchUsers={fetchUsers} /></div>
      <div><UpdateUser isVisible={showEditModal} onCancel={closeEditModal} uid={uid} aid={aid} users={users} fetchUsers={fetchUsers} /></div>
      <div style={containerStyle}>
        <div className="ag-theme-quartz" style={{ height: 600, width: "100%" }}>
          <AgGridReact
            defaultColDef={defaultColDef}
            rowData={userPermissionData}
            columnDefs={columnDefs}
            pagination={true}
            paginationAutoPageSize={true}
            components={{
              LinkComponent,
            }}
          />
        </div>
      </div>
    </>
  );
}
