import { Features, FeaturesAudit } from "../model/feature.model";
import { getApiUrl } from "../service/userService";
const axios = require("axios");

export async function getFeatures() {
  let apiUrl = getApiUrl();
  let featuresUrl = apiUrl + "/Features";

  try {
    const response = await axios.get(featuresUrl);
    return response?.data.features;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function updateFeature(feature: Features) {
  let apiUrl = getApiUrl();
  let updateFeatureUrl = apiUrl + "/Features";

  try {
    const response = await axios.put(updateFeatureUrl, feature);

    return response?.data;
  } catch (error: any) {
    console.error(error.response);
    throw error.response;
  }
}

export async function createFeature(feature: Features) {
  let apiUrl = getApiUrl();
  let addFeatureUrl = apiUrl + "/Features";
  try {
    const response = await axios.post(addFeatureUrl, feature);
    console.log("response",response);
    
    return response?.data;
  } catch (error: unknown) {
    console.error(error);
    throw error;
  }
}

export async function deleteFeature(featureKey: string) {
  const apiUrl = getApiUrl();
  const deleteFeatureUrl = `${apiUrl}/Features?featureKey=${featureKey}`;

  try {
    const response = await axios.delete(deleteFeatureUrl);

    return response?.data;
  } catch (error: unknown) {
    console.error(error);
    throw error;
  }
}

export async function updateFeatureKey(feature: {
  featureKey: string;
  updatedFeatureKey: string;
}) {
  const apiUrl = getApiUrl();
  const updateFeatureUrl = apiUrl + "/FeaturesByKey";

  try {
    const response = await axios.put(updateFeatureUrl, feature);

    return response?.data;
  } catch (error: any) {
    console.error(error.response);
    throw error.response;
  }
}

export async function getFeaturesAudit() {
  let apiUrl = getApiUrl();
  let featuresAuditUrl = `${apiUrl}/FeaturesAudit`;
  try {
    const response = await axios.get(featuresAuditUrl);
    return response?.data.featuresAudit;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function createFeatureAudit(featureAudit: FeaturesAudit) {
  let apiUrl = getApiUrl();
  let addFeatureAuditUrl = apiUrl + "/FeaturesAudit";  
  try {
    const response = await axios.post(addFeatureAuditUrl, featureAudit);
    return response?.data;
  } catch (error: unknown) {
    console.error(error);
    throw error;
  }
}