import React, { useState } from "react";
import { createFeature, createFeatureAudit } from "../../service/featureService";
import { Notification } from "../shared/notification";
import { UnionModal } from "../shared/modal/UnionModal";
import AdminUserInfo from "../../service/adminUserService";

interface CreateFeatureProps {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  fetchData: () => void;
}

const CreateFeature: React.FC<CreateFeatureProps> = ({
  showModal,
  setShowModal,
  fetchData
}) => {
  const [errorResponse, setErrorResponse] = useState<string>("");
  const [notificationMessage, setNotificationMessage] = useState<string>("");
  const [featureCreated, setFeatureCreated] = useState<boolean>(false);
  const adminUserInfo = AdminUserInfo();

  const [featureInfo, setFeatureInfo] = useState({
    featureId: 0,
    featureKey: "",
    production: false,
    uat: false,
    dev: false,
    qa: false,
  });

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const resetForm = () =>
    setFeatureInfo({
      featureId: 0,
      featureKey: "",
      production: false,
      uat: false,
      dev: false,
      qa: false,
    });

  const handleSubmit = async () => {
    try {
      const updatedFeatureInfo = { ...featureInfo };      
      const createdFeature = await createFeature(updatedFeatureInfo); 
      console.log(createdFeature);
           
      const backendFeatureId = createdFeature.featureId;
      setNotificationMessage("Feature created successfully");
      setFeatureCreated(true);
      setShowModal(false);
      const auditData = {
        id: 0,
        featureId: backendFeatureId,
        createdBy: adminUserInfo.email || "",
        createdOn: new Date().toISOString() || "",
        lastUpdatedBy: adminUserInfo.email || "",
        lastUpdatedOn: new Date().toISOString() || "",
        comments: `FeatureId ${backendFeatureId} was created by ${adminUserInfo.email}`,
      };
      createFeatureAudit(auditData);
      fetchData();
      setTimeout(() => {
        setNotificationMessage("");
        setFeatureCreated(false);
      }, 5000);
      resetForm();
    } catch (error) {
      if (error instanceof Error) {
        setErrorResponse(error.message || "Error creating Feature");
      } else {
        setErrorResponse("Error creating Feature");
      }
      console.error("Error creating Feature:", error);
    }
  };

  const handleChangeInput = (data: React.ChangeEvent<HTMLInputElement>) => {
    const featureKey = data.target.value.trim();
    setFeatureInfo((state) => ({ ...state, featureKey: featureKey }));
  };

  return (
    <div>
      {showModal && (
        <UnionModal
          title="Create Feature"
          size="small"
          actions={[
            {
              buttonId: "create-user",
              buttonLabel: "Create",
              buttonType: "Primary",
              onButtonClick: handleSubmit,
              buttonSize: "small",
            },
            {
              buttonId: "cancel-submit",
              buttonLabel: "Cancel",
              buttonType: "Subtle",
              onButtonClick: handleCloseModal,
              buttonSize: "small",
            },
          ]}
          onClose={handleCloseModal}
          actionsLayout={"end"}
        >
          <form className="form">
            <div className="user-access">
              <div>
                <label id="featureId">Feature Key *</label>
                <input
                  data-testid="text"
                  type="text"
                  name="featureId"
                  placeholder="Feature Key"
                  autoComplete="off"
                  onChange={handleChangeInput}
                  required
                />
                <label id="Description">Description</label>
                <input
                  data-testid="text"
                  type="text"
                  name="Description"
                  placeholder="Description"
                  autoComplete="off"
                />
              </div>
            </div>
          </form>
        </UnionModal>
      )}
      {featureCreated && (
        <Notification
          className="notification"
          type="success"
          message={notificationMessage}
        />
      )}
      {errorResponse && (
        <Notification
          className="notification"
          type="error"
          message={errorResponse}
        />
      )}
    </div>
  );
};

export default CreateFeature;
