import { useState, useEffect } from "react";
import { Footer } from "../footer";
import { AppHeader } from "../header";
import { AppRoutes } from "../routes";
import "./layout.scss";

export function Layout() {
  let [isParent, setIsParent] = useState(false);

  useEffect(() => {
    if (window === window.parent) {
      setIsParent(true);
    }
  }, []);
  return (
    <>
      {isParent && <AppHeader />}
      <div className="container">
        <AppRoutes />
      </div>
      {isParent && <Footer title="© 2022 IHS Markit. All Rights Reserved" />}
    </>
  );
}
